import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BASE } from '../../basedata/constants';

interface Ingresso {
  sector_id: string;
  gate_id: string | null;
  baixado: number;
  cancelado: number;
  validos: number;
}

interface Face {
  sector_id: string;
  gate_id: string | null;
  count: number;
}

const Integration = () => {
  const [dataIngressos, setDataIngressos] = useState<Ingresso[]>([]);
  const [dataFaces, setDataFaces] = useState<Face[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const transformDataIngressos = (data: any): Ingresso[] => {
    const resultMap: { [key: string]: Ingresso } = {};
    let totalCancelado = 0;
    let totalValido = 0;

    data.ticketSummary.forEach((item: any) => {
      const { sector_id, status, gate_id, ticket_count } = item;

      if (sector_id === null) {
        if (!resultMap['TotalGeral']) {
          resultMap['TotalGeral'] = {
            sector_id: '',
            gate_id: 'TOTAL GERAL',
            baixado: 0,
            cancelado: 0,
            validos: 0,
          };
        }

        if (status === 'Total') {
          resultMap['TotalGeral'].baixado = Number(ticket_count);
        } else if (status === 'Canceled') {
          resultMap['TotalGeral'].cancelado = Number(ticket_count);
        } else if (status === 'Active') {
          resultMap['TotalGeral'].validos = Number(ticket_count);
        }
      } else {
        if (!resultMap[sector_id]) {
          resultMap[sector_id] = {
            sector_id,
            baixado: 0,
            cancelado: 0,
            validos: 0,
            gate_id: gate_id || 'N/A',
          };
        }

        if (status === 'Total') {
          resultMap[sector_id].baixado = Number(ticket_count);
        } else if (status === 'Canceled') {
          resultMap[sector_id].cancelado = Number(ticket_count);
          totalCancelado += Number(ticket_count);
        } else if (status === 'Active') {
          resultMap[sector_id].validos = Number(ticket_count);
          totalValido += Number(ticket_count);
        }
      }
    });

    if (resultMap['TotalGeral']) {
      resultMap['TotalGeral'].cancelado = totalCancelado;
      resultMap['TotalGeral'].validos = totalValido;
    }

    return Object.values(resultMap);
  };

  const transformDataFaces = (data: any): Face[] => {
    return data.map((item: any) => ({
      sector_id: item.sector_id,
      gate_id: item.gate_id || 'N/A',
      count: Number(item.count),
    }));
  };

  const formatNumber = (num: number): string => {
    return num.toLocaleString('pt-BR');
  };

  const fetchIngressos = () => {
    setLoading(true);
    fetch(`${BASE.api.base_url}${BASE.api.tickets_counts}/2024-11-16`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const transformedData = transformDataIngressos(response);
        setDataIngressos(transformedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Erro ao carregar os ingressos');
        setLoading(false);
      });
  };

  const fetchFaces = () => {
    setLoading(true);
    fetch(`${BASE.api.base_url}${BASE.api.tickets_faces}/2024-11-16`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const transformedData = transformDataFaces(response);
        setDataFaces(transformedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Erro ao carregar os faces');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchIngressos();
    fetchFaces();
  }, []);

  if (loading) {
    return <div>Carregando dados...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const combinedData = dataIngressos.map((ingresso) => {
    const face = dataFaces.find((face) => face.sector_id === ingresso.sector_id);
    return {
      ...ingresso,
      facesCount: face ? face.count : 0,
    };
  });

  const totalFacesCount = combinedData.reduce((total, item) => total + item.facesCount, 0);

  return (
    <>
      <div className="container mt-4">
      <h2>Integração dos Ingressos</h2>
      <Table className="table table-striped table-bordered">
        <thead>
          <tr>
            {/* <th className="text-center">PORTÃO</th> */}
            <th className="text-center">SETOR</th>
            <th className="text-center">BAIXADOS</th>
            <th className="text-center">CANCELADOS</th>
            <th className="text-center">VÁLIDOS</th>
          </tr>
        </thead>
        <tbody>
          {dataIngressos
            .filter((item) => item.sector_id !== '')
            .map((item) => (
              <tr key={item.sector_id} className={item.sector_id}>
                {/* <td className="text-center">{item.gate_id}</td> */}
                <td className="text-center">{item.sector_id}</td>
                <td className="text-center">{formatNumber(item.baixado)}</td>
                <td className="text-center">{formatNumber(item.cancelado)}</td>
                <td className="text-center">{formatNumber(item.validos)}</td>
              </tr>
            ))}
          {dataIngressos.find((item) => item.gate_id === 'TOTAL GERAL') && (
            <tr>
              <td className="text-center">
                <strong>TOTAL GERAL</strong>
              </td>
              {/* <td className="text-center">
                <strong></strong>
              </td> */}
              <td className="text-center">
                <strong>{formatNumber(dataIngressos.find((item) => item.gate_id === 'TOTAL GERAL')?.baixado || 0)}</strong>
              </td>
              <td className="text-center">
                <strong>{formatNumber(dataIngressos.find((item) => item.gate_id === 'TOTAL GERAL')?.cancelado || 0)}</strong>
              </td>
              <td className="text-center">
                <strong>{formatNumber(dataIngressos.find((item) => item.gate_id === 'TOTAL GERAL')?.validos || 0)}</strong>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>

    <div className="container mt-4">
      <h2>SYNC</h2>
      <Table className="table table-striped table-bordered">
        <thead>
          <tr>
            {/* <th className="text-center">PORTÃO</th> */}
            <th className="text-center">SETOR</th>
            <th className="text-center">INGRESSOS VÁLIDOS</th>
            <th className="text-center">FACES (CPF único)</th>
          </tr>
        </thead>
        <tbody>
          {combinedData
            .filter((item) => item.sector_id !== '') 
            .map((item) => (
              <tr key={item.sector_id} className={item.sector_id}>
                {/* <td className="text-center">{item.gate_id}</td> */}
                <td className="text-center">{item.sector_id}</td>
                <td className="text-center">{formatNumber(item.validos)}</td>
                <td className="text-center">{formatNumber(item.facesCount)}</td>
              </tr>
            ))}
          <tr>
            <td className="text-center">
              <strong>Total Geral</strong>
            </td>
            {/* <td className="text-center">
              <strong></strong>
            </td> */}
            <td className="text-center">
              <strong>{formatNumber(dataIngressos.find((item) => item.gate_id === 'TOTAL GERAL')?.validos || 0)}</strong>
            </td>
            <td className="text-center">
              <strong>{formatNumber(totalFacesCount)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  </>
  );
};

export default Integration;









